body {
  background: #bf5700;
  font-family: 'Bree Serif', serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  display: inline-flex;
  background: #f8971f;
  height: 40px;
  width: 150px;
  background: fff;
  border: 2px solid #9cadb7;
  margin: 20px 20px 20px 20px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: .8em;
  font-family: 'Bree Serif', serif;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all .35s ease-Out;
  cursor: pointer;
  font-weight: bold;
}

button:hover {
  opacity: 0.8;
}

h1 {
  color: #fff;
}

.active {
  border-bottom: 8px solid white;
}

.container {
  display: inline-block;
  align-items: center;
}

.container h1 {
  font-size: 2rem;
}

.content-table {
  border-collapse: collapse;
  margin: 40px 0;
  font-size: 1rem;
  border-radius: 5px 5px 0 0;
  border-radius: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}

.content-table thead tr {
  color:white;
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;
}

.content-table th,
.content-table td {
  color:white;
  padding: 0.4rem 0.6rem;
}

.footer {
  color: white;
  text-align: center;
  position:relative;
  width: 100%;
  left: 0;
  bottom: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 20px;
}

.header a {
  text-decoration: none;
  color: white;
}

.header .navlink:hover, .header img:hover {
  opacity: 0.8;
}

.intro {
  color: white;
  width: 50%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 60px; */
}

.loading {
  color:white;
}

.logo {
  width: 200px;
}

.navlink {
  font-size: 2em;
  font-weight: bold;
}

.title {
  font-size: 400%;
}